import * as React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "@emotion/styled"

import hbg from "../images/hbg-5.jpg"

import Chevron from "../images/chevron.inline.svg"
import Arrow from "../images/icon-arrow-right.inline.svg"

const PageContain = styled.div`
    min-height: 60vh;
    font-family: "BS";
`

const PageHeader = styled.div`
  
  background: url(${hbg});
  background-size: cover;
  background-position: top center;
  height: 100%;
  width: 100%;
  padding: 7rem 1rem 3rem;

  h1 {
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--white);
    letter-spacing: 0.1rem;
  }

  @media (min-width: 550px) {

    padding: 8rem 2.5rem 3rem;
  }


  @media (min-width: 768px) {

    /* padding: 10rem 6rem 4rem; */

    h1 {
      font-size: 2rem;
    }
  }

  @media (min-width: 1024px) {

    padding: 12rem 2.5rem 4rem;

    h1 {
      font-size: 2.6rem;
    }
  }
`

const PageHeaderInner = styled.div`

  @media (min-width: 1490px) {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
  }
`


const PageContent = styled.div`

  padding: 2rem 1rem 3rem;

  @media (min-width: 550px) {
    padding: 2rem 2.5rem 3rem;
  }


  @media (min-width: 768px) {
    /* padding: 4rem 2.5rem 4rem; */
  }

  @media (min-width: 1024px) {
    padding: 4rem 2.5rem 4rem;
    }
`


const PageContentInner = styled.div`

  display: flex;
  flex-direction: row;
  align-items: flex-start;

  @media (min-width: 1490px) {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
  }
`

const Sidebar = styled.div`
  margin-right: 4rem;
  margin-top: 1rem;
  width: 200px;

  @media (max-width: 900px) {
    display: none;
  }

  ul {
    li {
      margin-bottom: 1.5rem;
      padding-bottom: 1.5rem;
      border-bottom: 2px solid rgba(0,0,0,.2);
      font-weight: 500;

      a {
        display: flex;
        flex-direction: row;
        align-items: center;

        svg {
          height: 24px;
          margin-top: 1px;
          width: auto;
          margin-right: 2px;
          fill: var(--primary);
          margin-left: 0;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    margin-right: 5rem;
  }

  @media (min-width: 1440px) {
    margin-right: 10rem;
  }
`

const MainContent = styled.div`
  flex: 1;

  h2 {
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.05rem;
    margin-bottom: 3rem;

    @media (min-width: 768px) {
      font-size: 2rem;
    }
  }

  h3 {
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 0.05rem;
    margin-bottom: 1rem;
    padding-bottom: .75rem;
    border-bottom: 3px solid rgba(0,0,0,.2);
    max-width: 800px;

    @media (min-width: 768px) {
      font-size: 1.5rem;
    }

    @media (min-width: 1200px) {
      max-width: none;
    }
  }

  h4 {
    font-size: .95rem;
    font-weight: 600;
    letter-spacing: 0.01rem;
    margin-bottom: .75rem;
    display: flex;
    align-items: center;

    &:first-of-type {margin-top:2rem;}

    span {
      font-weight: 500;
    }

    svg {
      height: 12px;
      width: auto;
      /* transform: rotate(90deg); */
      margin-right: 10px;
      fill: var(--primary);
    }

    @media (min-width: 768px) {
      font-size: 1.2rem;
    }
  }

  p {
    font-size: 1rem;
    line-height: 140%;
    max-width: 800px;
    margin-bottom: 1.5rem;

    a {
      text-decoration: underline;
    }

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: 768px) {
      font-size: 1.2rem;
    }
  }
`

const ContentBlock = styled.div`
  margin-bottom: 5rem;
`

const TeamMember = styled.div`
  margin-bottom: 2rem;
`

const Value = styled.div`
  /* margin-bottom: 2rem; */

  svg {
    height: 12px;
    width: auto;
    transform: rotate(90deg);
    margin-left: 10px;
    fill: var(--primary);
  }

`
const Accent = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-bottom: 4px solid var(--primary);
  width: 70px;
`

const ValuesGrid = styled.div`
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    max-width: 800px;

    @media (min-width: 900px) {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 2rem;
    }
`

const MainContentHeading = styled.div`
  margin-bottom: 4rem;
`



const MediaPage = () => (
  <Layout>
    <Seo title="Media & Press" />
    <PageContain>
      <PageHeader>
        <PageHeaderInner>
          <h1>Media</h1>
        </PageHeaderInner>
      </PageHeader>
      <PageContent>
        <PageContentInner>
          <Sidebar>
            <ul>
              <li><Link to="/about"><Chevron/>About Orion</Link></li>
              <li><AnchorLink to="/media/#inquiries" stripHash><Chevron/>Press Releases</AnchorLink></li>
            </ul>
          </Sidebar>
          <MainContent>
            <MainContentHeading>
              <h2>Media Inquiries & Press Releases</h2>
              <Accent/>
              <p>We appreciate your interest in Orion Constructors. Please direct all media inquiries to David Albertelli at <a href="mailto:dalbertelli@orionconstructors.com">dalbertelli@orionconstructors.com</a>.</p>
              <p>For general information on Orion please visit our <Link to="/about">about page</Link>.</p>
            </MainContentHeading>
            <ContentBlock id="inquiries">
              <h3>Press Releases</h3>
              <p>There are no press releases for the current year.</p>
            </ContentBlock>
          </MainContent>
        </PageContentInner>
      </PageContent>
    </PageContain>
  </Layout>
)

export default MediaPage
